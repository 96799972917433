import React from 'react';
import RootElement from './src/components/root-element';

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  const IDs = [
    'G-H6ZHW76GX0',
  ]

  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      IDs.forEach((id) => {
        // window.gtag('event', 'page_view', { page_path: pagePath, 'send_to': id });
      });
    }
  }, 100);

  return true;
};

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};
